<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div>
        <h6 class="title-h6 q-mt-none">{{$t('processes.linked_pdv_wallets')}}</h6>
        <div class="row q-col-gutter-md q-mb-md">
          <add-a-wallet v-if="currentSuiviDesDemenagementsProcess && currentSuiviDesDemenagementsProcess.wallets" @walletsUpdated="updateWallet" :pdv="1" v-model="currentSuiviDesDemenagementsProcess.wallets" :wallets="currentSuiviDesDemenagementsProcess.wallets" class="col"/>
          <add-a-wallet v-else @walletsUpdated="updateWallet" :pdv="1" class="col"/>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_reception_demande')" :color="color"
                v-model="formData.date_reception_demande" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_demenagement_souhaitee')" :color="color"
                v-model="formData.date_demenagement_souhaitee" ref="label" />
            </div>
            <div class="row">
              <div class="col-12 q-gutter-sm">
                <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.motifs.label')}}</q-item-label>
                <base-input-text class="q-px-sm q-pb-none row" placeholder="" label="" v-bind="formInputProps('motifs')" :color="color"
                  v-model="formData.motifs" ref="label" />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <base-input-date class="col" v-bind="formInputProps('date_demande_mise_conformite')" :color="color"
                  v-model="formData.date_demande_mise_conformite" ref="label" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.demandeEtudeAtlas')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_demande_etude_atlas')" :color="color"
                v-model="formData.date_demande_etude_atlas" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_reception_etude_atlas')" :color="color"
                v-model="formData.date_reception_etude_atlas" ref="label" />
            </div>
            <div class="row">
              <div class="col-12 q-gutter-sm">
                <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.conclusion_etude.label')}}</q-item-label>
                <base-input-text class="q-px-sm q-pb-none row" placeholder="" label="" v-bind="formInputProps('conclusion_etude')" :color="color"
                  v-model="formData.conclusion_etude" ref="label" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.decision')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-select class="q-mt-none col" :options="booleanOption" v-bind="formInputProps('proposition_validee')" :color="color"
                v-model="formData.proposition_validee" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_decision_communiquee')" :color="color"
                v-model="formData.date_decision_communiquee" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item">
        <h6>{{$t('processes.links')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="row q-gutter-md collapsible-div">
          <div v-if="relatedLinks.length" class="column col col-6 q-py-md q-col-gutter-md">
            <div class="row q-gutter-sm" v-for="relatedLink in relatedLinks" :key="relatedLink.id">
              <base-card :showSeparator="false" @menuClick="onMenuClick" class="col-12" :actions="relatedLink.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
              </base-card>
            </div>
          </div>

          <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
            <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
          </div>

          <div v-else class="col-6">
            <q-card flat bordered class="q-pa-md">
              <base-input-text v-bind="titleTemp" :color="color"
                  v-model="link.titre" ref="admin_note" />
              <base-input-text v-bind="linkTemp" :color="color"
                  v-model="link.lien" ref="admin_note" />
              <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink()">{{$t('form.ok')}}</q-btn>
              <div style="clear: both;"></div>
            </q-card>
          </div>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import addAWallet from '../shared/addAWallet'

export default {
  mixins: [BaseForm],
  components: { addAWallet },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      searchBoxActivated: false,
      isUpdate: this.$route.params.id,
      booleanOption: [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ],

      // For links
      relatedLinks: [],
      isLinksFormOpen: false,
      titleTemp: { label: 'Titre du lien' },
      linkTemp: { label: 'Chemin réseau' },
      link: {
        titre: '',
        lien: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentSuiviDesDemenagementsProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    })
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'demenagement'
        if (!newVal || !this.currentSuiviDesDemenagementsProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options, type, boolean }) => ({ id, options, type, boolean }))
            .reduce((acc, { id, options, type, boolean }) => ({
              ...acc,
              [id]: options
                ? options.find(({ value }) => value === this.currentSuiviDesDemenagementsProcess[id])
                : type === 'select' && boolean
                  ? this.booleanOption.find(val => val.value === this.currentSuiviDesDemenagementsProcess[id])
                  : this.currentSuiviDesDemenagementsProcess[id]
            }), {})
        }

        this.formData.related_objects = []
        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id

          // Set wallets data
          let walletsMapped = this.currentSuiviDesDemenagementsProcess.wallets.map(wallet => ({
            model_type: 'wallet',
            model_id: wallet.id
          }))
          this.formData.related_objects.push(...walletsMapped)
          // Set links
          this.relatedLinks = this.currentSuiviDesDemenagementsProcess.lien_etude_atlas ? JSON.parse(this.currentSuiviDesDemenagementsProcess.lien_etude_atlas).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []
        }
      },
      immediate: true
    },
    relatedLinks (newVal, oldVal) {
      this.formData.lien_etude_atlas = newVal.length > 0 ? JSON.stringify(newVal.filter(lien => lien.id !== null).map(({ titre, lien, id }) => ({ titre, lien, id }))) : null
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_des_demenagements'), to: { name: 'suivi-des-demenagements-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-des-demenagements-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_des_demenagements'))

    this.$store.commit('pages/setTabs', [
      { name: 'suivi-des-demenagements-form', label: 'Informations', to: { name: 'suivi-des-demenagements-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'demenagement' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'demenagement')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'demenagement')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    addLink () {
      if (this.link.titre === '' || this.link.lien === '') return
      let tempId = 0
      if (!this.relatedLinks) {
        this.relatedLinks = []
      } else if (this.relatedLinks.length) {
        tempId = Math.max.apply(Math, this.relatedLinks.map(link => link.id)) + 1
      }

      // Remove " first before adding
      let copiedObject = Object.assign({}, this.link)
      copiedObject.id = tempId
      copiedObject.lien = copiedObject.lien.replace(/"/g, '')
      copiedObject.actions = [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'delete-link', params: { id: tempId } } }]

      this.relatedLinks.push(copiedObject)
      this.link.titre = ''
      this.link.lien = ''
      this.isLinksFormOpen = false
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-des-demenagements-form', params: { id } })
    },
    updateWallet (wallets) {
      console.log(wallets)
      let walletMapped = wallets.map(wallet => ({
        model_type: 'wallet',
        model_id: wallet.id
      }))

      if (!this.formData.related_objects) this.formData.related_objects = []
      // Remove all wallet in related object
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'wallet')
      this.formData.related_objects.push(...walletMapped)
    },
    onMenuClick ({ name, params }) {
      if (name === 'delete-link') {
        this.relatedLinks = this.relatedLinks.filter(link => link.id !== params.id)
      }
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'demenagement', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .process-color
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
